<template>
  <div class="ResponsiveContentBoard">
    <div v-if="clientEdit">
      <div class="mainTitle textAverage">{{title}}</div>
      <div class="form">
        <div class="row">
          <div class="colLabel">
            <label class="textSmall sideFloat">Nome: </label>
          </div>
          <div class="colImput">
            <input class="input textSmall" type="text" v-model="name" @blur="checkNameUsed()">
            <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgName}}</span>
          </div>
          <div class="colLabel">
            <label class="textSmall sideFloat">CNPJ Matriz:</label>
          </div>
          <div class="colImput">
            <input class="input textSmall" type="text" v-model="cnpj" @input="formatCNPJ()" @blur="searchCNPJ()">
            <span class="textErro" style="margin: 3px 0 0 15px;">{{errorMsgCNPJ}}</span>
          </div>
        </div>
      </div>
      <div class="lists">
        <div class="UnidadeArea">
          <div class="textSmall labelTableTitle">Unidades</div>
          <div class="inputContainer">
            <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="unitySearch">
            <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
          </div>
          <div class="tableInternalArea">
            <table class="tableNormal textSmall">
              <tr>
                <th class="ResponsiveTableHead">Nome</th>
                <th class="ResponsiveTableHead">CNPJ</th>
                <th class="ResponsiveTableHead btnColumn">
                  <div @click="addUnit()" class="btnQuareIcon btnTypeSave" :class="{btnTypeDisabled: newClient}" style="float: right">
                    <img class="verySmallIconBtn" src="@/assets/icons/outros/plusDevice.svg">
                  </div>
                </th>
              </tr>
              <tr v-for="(unity, index) in unityListFilter" :key="index">
                <td>{{unity.name}}</td>
                <td>{{unity.cnpj}}</td>
                <td class="ResponsiveCellConfig">
                  <div @click="editUnit(unity)" class="btnIcon btnSmallSize btnTypeSave">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
                  </div>
                  <div @click="deleteUnit(unity)" class="btnIcon btnSmallSize btnTypeDelete">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/trash.svg" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="userArea">
          <div class="textSmall labelTableTitle">Usuário</div>
          <div class="inputContainer">
            <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="userSearch">
            <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
          </div>
          <div class="tableInternalArea">
            <table class="tableNormal textSmall">
              <tr>
                <th class="ResponsiveTableHead">Nome</th>
                <th class="ResponsiveTableHead">E-mail</th>
                <th class="ResponsiveTableHead btnColumn">
                  <div @click="addUser()" class="btnQuareIcon btnTypeSave" style="float: right">
                    <img class="verySmallIconBtn" src="@/assets/icons/outros/plusDevice.svg">
                  </div>
                </th>
              </tr>
              <tr v-for="(user, index) in userListFilter" :key="index">
                <td>{{user.name}}</td>
                <td>{{user.email}}</td>
                <td class="ResponsiveCellConfig">
                  <div @click="editUser(user)" class="btnIcon btnSmallSize btnTypeSave">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
                  </div>
                  <div @click="deleteUser(user)" class="btnIcon btnSmallSize btnTypeDelete">
                    <img class="smallIconBtn" src="@/assets/icons/Setting/trash.svg" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <UserPermission :show="showPopupPermissionUser" :user="userEdit" @close="showPopupPermissionUser = false"></UserPermission>
      <div class="ResponsiveBtnConfigArea">
        <button @click="save()" class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Salvar</button>
        <button @click="close()" class="btn btnNormalSize btnTypeCancel">Voltar</button>
      </div>
      <PopUp msg="Salvando Cliente" :show="loadData" type="Loader"/>
      <PopUp :msg="faultMsg" :show="faultOnUpdateClient" type="Fault" :onOk="popUpOk"/>
    </div>
    <UnityEdit v-if="unityEdit" :unity="unityToEdit" :clientEdit="true" :componet="true" @close="unityEdit = false, clientEdit = true"></UnityEdit>
    <NewUser v-if="newUser" :componet="true" @close="newUser = false, clientEdit = true, showPopupFilterList = true"></NewUser>
    <FilterList
      :type="filterListType"
      :show="showPopupFilterList"
      :list="listToFilter"
      @close="showPopupFilterList = false"
      @addItems="fillList($event), showPopupFilterList = false"
      @newUserView="newUser = true, showPopupFilterList = false, clientEdit = false">
    </FilterList>
  </div>
</template>

<script>
  import PopUp from '@/views/PopUp/PopUp.vue'
  import FilterList from "../popups/FilterList"
  import UserPermission from "../popups/UserPermission"
  import ClientDAO from '@/sys/Control/Client/ClientDAO'
  import UnityDAO from '@/sys/Control/Unity/UnityDAO'
  import NewUser from '../Users/UserEdit'
  import UnityEdit from '../Unities/UnityEdit'

  export default {
    components: { FilterList, UserPermission, NewUser, UnityEdit, PopUp },
    props: {
      client: Object,
      newClient: Boolean
    },
    methods: {
      removeItemList(item, list) {
        for(let i=0; i < list.length; i++) {
          if(item._id == list[i]._id) {
            list.pop(i);
            break;
          }
        }
      },
      modifyUserList(userList){
        for(let i=0; i < userList.length; i++) {
          userList[i] = {
            _id: userList[i]._id,
            realmId: userList[i].realmId,
            name: userList[i].name,
            email: userList[i].email,
            accessLevel: userList[i].accessLevel,
            permissions: userList[i].permissions,
            addFromClient: true
          }
        }
      },
      async checkNameUsed(){
        if(this.name != this.nameAux) {
          let clientName = await ClientDAO.readClientByName(this.name);
          if(clientName) {
              this.errorMsgName = 'Este nome já está em uso';
          } else {
              this.errorMsgName = '';
          }
        } else {
          this.errorMsgName = '';
        }
      },
      formatCNPJ(){
        var x = this.cnpj.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        this.cnpj = (x[2] ? x[1] + '.' + x[2] : x[1]) + (x[3] ? '.' + x[3] : '') + (x[4] ? '/' + x[4] : '') + (x[5] ? '-' + x[5] : '');
      },
      async searchCNPJ(){
        if(this.cnpj != '' && this.errorMsgCNPJ == ''){
          console.log('Searching CNPJ...');
          let strCNPJ = this.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
          this.getDataCNPJ(strCNPJ);
        }
      },
      getDataCNPJ(strCNPJ){
        var $jsonp = (function(){
          var that = {};

          that.send = function(src, options) {
            var callback_name = options.callbackName || 'callback',
              on_success = options.onSuccess || function(){},
              on_timeout = options.onTimeout || function(){},
              timeout = options.timeout || 5; // sec

            var timeout_trigger = window.setTimeout(function(){
              window[callback_name] = function(){};
              on_timeout();
            }, timeout * 1000);

            window[callback_name] = function(data){
              window.clearTimeout(timeout_trigger);
              on_success(data);
            }

            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = src;

            document.getElementsByTagName('head')[0].appendChild(script);
          }

          return that;
        })();

        let self = this
        $jsonp.send('https://www.receitaws.com.br/v1/cnpj/' + strCNPJ + '?callback=handleStuff', {
          callbackName: 'handleStuff',
          onSuccess: function(json){
            if(json.status != 'ERROR') {
              self.errorMsgCNPJ = ''
              console.log('Valid CNPJ!!', json);
            } else {
              self.errorMsgCNPJ = 'CNPJ inválido'
              console.log('Invalid CNPJ!!', json);
            }
          },
          onTimeout: function(){
            self.errorMsgCNPJ = ''
            console.log('Timeout CNPJ!!');
          },
          timeout: 5
        });
      },
      async checkCNPJUsed(){
        if(this.errorMsgCNPJ == '') {
          if(this.cnpj.length == 18) {
            if(this.cnpj != this.cnpjAux) {
              let clientCNPJ = await ClientDAO.readClientByCNPJ(this.cnpj);
              if(clientCNPJ) {
                  this.errorMsgCNPJ = 'Este CNPJ já está em uso';
              } else {
                  this.errorMsgCNPJ = '';
              }
            } else {
              this.errorMsgCNPJ = '';
            }
          } else {
            this.errorMsgCNPJ = 'Insira um CNPJ válido';
          }
        }
      },
      async save(){
        if(this.name == ''){
          this.errorMsgName = 'Preencha este campo'
        } else {
          await this.checkNameUsed();
        }
        if(this.cnpj == ''){
          this.errorMsgCNPJ = 'Preencha este campo'
        } else {
          await this.checkCNPJUsed();
        }
        if(this.errorMsgName == '' && this.errorMsgCNPJ == ''){
          this.loadData = true

          this.modifyUserList(this.userList);

          for(let i=0; i < this.unityList.length; i++) {
            this.unityList[i] = {
              _id: this.unityList[i]._id,
              name: this.unityList[i].name,
              cnpj: this.unityList[i].cnpj,
              address: this.unityList[i].address,
              city: this.unityList[i].city,
              state: this.unityList[i].state,
              country: this.unityList[i].country,
              zipCode: this.unityList[i].zipCode,
              gpsCoordinates: this.unityList[i].gpsCoordinates,
              deviceList: this.unityList[i].deviceList,
              userList: this.unityList[i].userList
            }
          }

          if(this.newClient){
            await ClientDAO.createClient({
              name: this.name.trim(),
              cnpj: this.cnpj,
              unityList: this.unityList,
              userList: this.userList,
              createdAt: new Date(),
              updatedAt: new Date(),
              deletedAt: null
            }).catch(e=>this.popUpException(e));
          }else{
            await ClientDAO.updateClient({
              _id: this.client._id,
              name: this.name.trim(),
              cnpj: this.cnpj,
              unityList: this.unityList,
              userList: this.userList,
              createdAt: this.client.createdAt,
              updatedAt: new Date(),
              deletedAt: this.client.deletedAt
            }).catch(e=>this.popUpException(e));

            if(this.removeUserList.length > 0) {
              this.modifyUserList(this.removeUserList);
              await UnityDAO.removeUnityClientUsers(this.client._id, this.removeUserList).catch(e=>this.popUpException(e));
            }

            if(this.removeUnityList.length > 0) {
              await UnityDAO.removeUnityClient(this.removeUnityList).catch(e=>this.popUpException(e));
            }

            if(this.addUserList.length > 0) {
              this.modifyUserList(this.addUserList);
              await UnityDAO.addUnityClientUsers(this.client._id, this.addUserList).catch(e=>this.popUpException(e));
            }

            if(this.addUnityList.length > 0) {
              UnityDAO.addUnityClient({_id: this.client._id, name: this.client.name}, this.addUnityList, this.userList).catch(e=>this.popUpException(e));
            }
          }
          this.loadData = false
          if(!this.faultOnUpdateClient) {
            this.$emit('close');
          }
        }
      },
      close(){
        this.$emit('close');
      },
      addUnit(){
        if(!this.newClient){
          this.listToFilter = this.unityList
          this.filterListType = this.enumListType.unity
          this.showPopupFilterList = true
        }
      },
      async editUnit(unity){
        this.unityToEdit = await UnityDAO.readUnity(unity.cnpj);
        this.unityEdit = true
        this.clientEdit = false
      },
      deleteUnit(unity){
        unity.select = false
        this.removeItemList(unity, this.addUnityList)
        this.removeUnityList.push(unity)
        this.unityList = this.unityList.filter(currentUnity => {return currentUnity.cnpj != unity.cnpj})
      },
      addUser(){
        this.listToFilter = this.userList
        this.filterListType = this.enumListType.user
        this.showPopupFilterList = true
      },
      async editUser(user){
        this.userEdit = await UserDAO.readUser(user.email)
        this.showPopupPermissionUser = true
      },
      deleteUser(user){
        user.select = false
        this.removeItemList(user, this.addUserList)
        this.removeUserList.push(user)
        this.userList = this.userList.filter(currentUser => {return currentUser.email != user.email})
      },
      fillList(items){
        if(this.enumListType.user == this.filterListType){
          for (const user in items) {
            this.userList.push(items[user])
            this.addUserList.push(items[user])
            this.removeItemList(items[user], this.removeUserList)
          }
        }else if(this.enumListType.unity == this.filterListType){
          for (const unity in items) {
            this.unityList.push(items[unity])
            this.addUnityList.push(items[unity])
            this.removeItemList(items[unity], this.removeUnityList);
          }
        }
      },
      popUpOk: function(){
        this.faultOnUpdateClient=false;
        this.$emit('close');
      },
      popUpException: function(e){
        this.loadData = false;
        this.faultMsg=e;
        this.faultOnUpdateClient=true;
      }
    },
    computed: {
      userListFilter(){
        if(this.userList.length > 0) {
          return this.userList.filter(user => {
            return user.name.toLowerCase().includes(this.userSearch.toLowerCase()) ||
              user.email.toLowerCase().includes(this.userSearch.toLowerCase())
          })
        }
      },
      unityListFilter(){
        if(this.unityList.length > 0) {
          return this.unityList.filter(unity => {
            return unity.name.toLowerCase().includes(this.unitySearch.toLowerCase()) ||
              unity.cnpj.toLowerCase().includes(this.unitySearch.toLowerCase())
          })
        }
      }
    },
    watch:{
      cnpj(){
        this.errorMsgCNPJ = ''
      }
    },
    data() {
      return {
        name: '',
        nameAux: '',
        cnpj: '',
        errorMsgName: '',
        errorMsgCNPJ: '',
        userSearch: '',
        unitySearch: '',
        showPopupFilterList: false,
        showPopupPermissionUser: false,
        userEdit: null,
        userList: [],
        unityList: [],
        addUnityList: [],
        removeUnityList: [],
        addUserList: [],
        removeUserList: [],
        listToFilter: [],
        filterListType: null,
        enumListType: {user: 1, unity: 2, device: 3},
        clientEdit: true,
        newUser: false,
        unityEdit: false,
        unityToEdit: null,
        title: '',
        loadData: false,
        faultOnUpdateClient: false,
        faultMsg: null
      };
    },
    created() {
      if(this.client){
        this.title = 'Editar Cliente'
        this.name = this.client.name
        this.nameAux = this.name
        this.cnpj = this.client.cnpj
        this.cnpjAux = this.cnpj
        this.unityList = this.client.unityList
        this.userList = this.client.userList
      }else{
        this.title = 'Novo Cliente'
      }
    }
  };
</script>

<style scoped>
  .sideFloat{
    float: right;
  }
  .form{
    display: table;
    width: 80%;
    margin-left: 10%;
    margin-top: 20px;
  }
  .row{
    display: table-row;
    height: 80px;
  }
  .colLabel{
    width: 15%;
    float: left;
    padding: 5px 30px 5px 0;
    display: table-cell;
    vertical-align: top;
  }
  .colImput{
    position: relative;
    width: 35%;
    float: left;
    padding: 0 5px;
    display: table-cell;
    vertical-align: top;
    margin-bottom: 30px
  }
  .lists{
    display: inline-block;
    padding: 0 10px;
    width: 100%;
  }
  .UnidadeArea{
    position: relative;
    width: 50%;
    height: 680px;
    float: left;
    margin-bottom: 15px;
  }
  .userArea{
    position: relative;
    width: 49%;
    height: 680px;
    float: right;
  }
  .tableInternalArea {
    position: absolute;
    bottom: 0px;
    top: 45px;
    left: 0;
    right: 0;
    background-color: #1e2227;
    overflow: auto;
  }
  @media(max-width: 1700px){
    .form{
      width: 90%;
      margin-left: 5%;
    }
    .colLabel{
      width: 35%;
    }
    .colImput{
      width: 65%;
    }
    .UnidadeArea{
      width: 100%
    }
    .userArea{
      width: 100%
    }
  }
  @media(max-width: 1000px){
    .colLabel{
      width: 30%;
    }
  }
  @media(max-width: 825px){
    .colLabel{
      width: 100%;
    }
    .colImput{
      width: 100%;
      margin-bottom: 15px
    }
    .sideFloat{
      float: left
    }
    .lists{
      padding: 0 5px;
    }
  }
</style>