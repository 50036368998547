<template>
  <div class="ResponsiveContentBoard">
    <div v-if="!clientEdit">
      <div class="mainTitle textAverage">Lista de Clientes</div>
      <div class="inputContainer">
        <input class="inputSearch textSmall" type="text" placeholder="Pesquisar" v-model="clientSearch">
        <img class="searchIcon" src="@/assets/icons/outros/search2.svg" />
      </div>
    </div>
    <div v-if="!clientEdit" class="tableArea">
      <div class="tableInternalArea">
        <table class="tableNormal minSize textSmall">
          <tr>
            <th class="ResponsiveTableHead">Nome</th>
            <th class="ResponsiveTableHead">CNPJ Matriz</th>
            <th class="ResponsiveTableHead btnColumn"></th>
          </tr>
          <tr v-for="(client, index) in clientListFilter" :key="index">
            <td>{{client.name}}</td>
            <td>{{client.cnpj}}</td>
            <td class="ResponsiveCellConfig">
              <div @click="editClient(client)" class="btnIcon btnSmallSize btnTypeSave">
                <img class="smallIconBtn" src="@/assets/icons/Setting/edit.svg" />
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="!clientEdit" class="ResponsiveBtnConfigArea">
      <button @click="newClientBtn()" class="btn btnNormalSize btnTypeSave" style="margin-left: 16px">Novo</button>
      <button @click="close()" class="btn btnNormalSize btnTypeCancel">Voltar</button>
    </div>
    <ClientEdit v-else :client="client" :newClient="newClient" @close="closeClientEdit()"></ClientEdit>
    <PopUp msg="Carregando lista de clientes..." :show="load" type="Loader"/>
    <PopUp :msg="faultMsg" :show="faultOnLoadClients" type="Fault" :onOk="popUpOk"/>
  </div>
</template>

<script>
import PopUp from '@/views/PopUp/PopUp.vue'
import ClientEdit from "./ClientEdit"
import ClientDAO from '@/sys/Control/Client/ClientDAO'

export default {
  components: {ClientEdit, PopUp},
  methods: {
    async closeClientEdit(){
      this.client = null
      this.clientEdit = false
      this.clientList = await ClientDAO.readAllActiveClients();
    },
    newClientBtn(){
      this.newClient = true
      this.clientEdit = true
      this.client = null
    },
    editClient(client) {
      this.client = client
      this.newClient = false
      this.clientEdit = true
    },
    close(){
      this.$router.push({path: '/Setting/ChooseSetting'})
    },
    popUpOk: function(){
      this.faultOnLoadClients=false;
    }
  },
  computed: {
    clientListFilter(){
      if(this.clientList != null) {
        return this.clientList.filter(client => {
          return client.name.toLowerCase().includes(this.clientSearch.toLowerCase()) || client.cnpj.includes(this.clientSearch)
        })
      }
    }
  },
  watch: {
    clientList() {
      if(this.clientList != null) {
        this.load = false;
      }
    }
  },
  data() {
    return {
      load: true,
      client: null,
      newClient: false,
      clientList: null,
      clientEdit: false,
      clientSearch: '',
      faultOnLoadClients: false,
      faultMsg: null
    };
  },
  async created() {
    this.clientList = await ClientDAO.readAllActiveClients().catch(e=>{
      this.load = false;
      this.faultMsg=e;
      this.faultOnLoadClients=true;
    });
  }
};
</script>

<style scoped>
.ResponsiveContentBoard {
  flex-flow: column nowrap;
}
.tableArea {
  flex-grow: 1;/* Define que a tabela é o unico item que pode mudar de tamanho */
  position: relative;
}
.tableInternalArea {
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 10px;/*Usado como paddin da tabela */
  right: 10px;/*Usado como paddin da tabela */
  background-color: #1e2227;
  overflow: auto;
}
@media(max-width: 825px){
  .tableInternalArea {
    top: 10px;
    left: 5px;/*Usado como paddin da tabela */
    right: 5px;/*Usado como paddin da tabela */
  }
}
</style>